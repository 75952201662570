import classes from './Spinner.component.module.scss';

import React from 'react';
import cnj from '../utils/cnj';
import Flex from '../Flex';
import type { colorsKeys } from '../helpers/theme';

interface SpinnerProps {
  className?: string;
  color?: colorsKeys;
}

const Spinner = ({ className, color = 'brand' }: SpinnerProps): JSX.Element => {
  return (
    <Flex as="span" className={classes.container}>
      <Flex
        as="span"
        className={cnj(
          'loader',
          classes.size,
          classes.border,
          classes[`borderColor-${color}`],
          classes.borderColor,
          className
        )}
      />
    </Flex>
  );
};

export default Spinner;
