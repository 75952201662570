import React, { forwardRef } from 'react';
import { preventClickHandler } from '@lobox/utils';
import type { fontType, TypographyProps } from '../Typography';
import Typography from '../Typography';
import type { IconFontType } from '../Icon';
import Icon from '../Icon';
import Link from '../Link';
import cnj from '../utils/cnj';
import type { colorsKeys } from '../helpers/theme';
import Spinner from '../Spinner';
import classes from './Button.component.module.scss';
import BaseButton from './BaseButton';

const schemaProperties = {
  'primary-light': {
    backgroundColor: 'lightGray',
    hoverBackgroundColor: 'hover_2',
    color: 'graphene',
  },
  'secondary-light': {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'hover_2',
    color: 'graphene',
  },
  'primary-blue': {
    backgroundColor: 'brand',
    hoverBackgroundColor: 'trench',
    color: 'white',
  },
  'semi-transparent': {
    backgroundColor: 'brand_10',
    hoverBackgroundColor: 'brand_20',
    color: 'brand',
  },
  'semi-transparent2': {
    backgroundColor: 'brand_20',
    hoverBackgroundColor: 'brand_30',
    color: 'brand',
  },
  ghost: {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'techGray_20',
    color: 'primaryText',
  },
  'ghost-black': {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'hover_75',
    color: 'disabledGray',
  },
  'light-gray': {
    backgroundColor: 'hover_50',
    hoverBackgroundColor: 'hover',
    color: 'highlightIcon',
  },
  'secondary-dark': {
    backgroundColor: 'darkSecondary',
    hoverBackgroundColor: 'muteMidGray',
    color: 'disabledGray',
  },
  'dark-primary': {
    backgroundColor: 'disabledGray',
    hoverBackgroundColor: 'hover_2',
    color: 'popOverBg',
  },
  black: {
    backgroundColor: 'popOverBg',
    hoverBackgroundColor: 'hover_75',
    color: 'disabledGray',
  },
  'dark-gray': {
    backgroundColor: 'darkSecondary',
    hoverBackgroundColor: 'hover_75',
    color: 'disabledGray',
  },
  transparent: {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    color: 'disabledGray',
  },
  'transparent-mute': {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'transparent',
    color: 'primaryDisabledText',
  },
  'ghost-brand': {
    backgroundColor: 'ghost',
    hoverBackgroundColor: 'brand_10',
    color: 'brand',
  },
  'ghost-orange': {
    backgroundColor: 'ghost',
    hoverBackgroundColor: 'pendingOrange_10',
    color: 'pendingOrange',
  },
  'orange-semi-transparent': {
    backgroundColor: 'pendingOrange_10',
    hoverBackgroundColor: 'pendingOrange_50',
    color: 'pendingOrange',
  },
  'success-semi-transparent': {
    backgroundColor: 'success_10',
    hoverBackgroundColor: 'success_50',
    color: 'success',
  },
  success: {
    backgroundColor: 'success',
    hoverBackgroundColor: 'success',
    color: 'white',
  },
  'brown-semi-transparent': {
    backgroundColor: 'brown_4',
    hoverBackgroundColor: 'brown_4',
    color: 'brown',
  },
  'error-semi-transparent': {
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'error_10',
    color: 'error',
  },
  'gray-semi-transparent': {
    backgroundColor: 'gray_10',
    hoverBackgroundColor: 'hoverPrimary',
    color: 'primaryText',
  },
  gray: {
    backgroundColor: 'backgroundIconSecondary',
    hoverBackgroundColor: 'hoverPrimary',
    color: 'primaryText',
  },
  background: {
    backgroundColor: 'background',
    hoverBackgroundColor: 'hoverPrimary',
    color: 'primaryText',
  },
};

export type ButtonSchemaType = keyof typeof schemaProperties;
type SchemaItem = {
  backgroundColor: colorsKeys;
  hoverBackgroundColor: colorsKeys;
  color: colorsKeys;
};
const variantHeight = {
  default: 32,
  thin: 24,
  large: 42,
};
export interface ButtonProps {
  onClick?: (event?: React.MouseEvent<HTMLElement>) => any;
  className?: string;
  href?: string;
  to?: string;
  leftSvg?: React.ReactNode;
  leftIcon?: string;
  leftType?: IconFontType;
  leftSize?: number;
  leftColor?: colorsKeys;
  leftIconClassName?: string;
  rightSvg?: React.ReactNode;
  rightIcon?: string;
  rightType?: IconFontType;
  rightSize?: number;
  rightColor?: colorsKeys;
  rightIconClassName?: string;
  label?: React.ReactNode;
  labelSize?: number;
  labelFont?: fontType;
  labelColor?: colorsKeys;
  labelClassName?: string;
  labelProps?: Partial<TypographyProps>;
  children?: React.ReactNode;
  schema?: ButtonSchemaType;
  variant?: 'default' | 'large' | 'thin' | 'text';
  shape?: 'rectangle' | 'capsule';
  disabled?: boolean;
  fullWidth?: boolean;
  tabIndex?: number;
  isLoading?: boolean;
  tooltip?: string;
  tPlacement?: string;
  target?: string;
}

const Button = (
  {
    onClick,
    className,
    href,
    to,
    leftSvg,
    leftIcon,
    leftType,
    leftSize = 14,
    leftColor,
    leftIconClassName,
    rightSvg,
    rightIcon,
    rightType,
    rightSize = 14,
    rightColor,
    rightIconClassName,
    label,
    labelSize,
    labelFont = '700',
    labelColor,
    labelClassName,
    labelProps,
    children,
    schema = 'primary-blue',
    variant = 'default',
    shape = 'rectangle',
    disabled: dd,
    fullWidth,
    tabIndex,
    isLoading,
    tooltip,
    tPlacement,
    target,
  }: ButtonProps,
  ref: any
) => {
  const buttonSchema = schemaProperties[schema] as SchemaItem;

  const disabled = dd || isLoading;
  const handleDisableClick = (e: any) => {
    preventClickHandler(e);
  };

  const Wrapper = disabled ? 'span' : href ? 'a' : to ? Link : 'span';
  const wrapperProps = disabled
    ? { onClick: handleDisableClick }
    : href
      ? { target, href }
      : to
        ? { to }
        : { onClick };

  if (variant === 'text') {
    return (
      <BaseButton onClick={onClick}>
        <Typography
          font={labelFont}
          color={labelColor || buttonSchema.color}
          className={cnj(classes.label, labelClassName)}
          size={labelSize}
          {...labelProps}
        >
          {label}
        </Typography>
      </BaseButton>
    );
  }

  return (
    <Wrapper
      data-tip={tooltip}
      data-place={tPlacement}
      ref={ref}
      className={cnj(
        classes.buttonRoot,
        fullWidth && classes.fullWidth,
        classes[`backgroundColor-${buttonSchema.backgroundColor}`],
        variant === 'thin' && classes.heightThin,
        variant === 'large' && classes.heightLarge,
        shape === 'rectangle' && classes.rectangle,
        classes[`backgroundColorHover-${buttonSchema.hoverBackgroundColor}`],
        className,
        disabled && classes.disabledStyle,
        disabled &&
          classes[`backgroundColorHover-${buttonSchema.backgroundColor}`]
      )}
      {...wrapperProps}
      tabIndex={tabIndex}
      // @ts-ignore
      disabled={disabled}
    >
      {isLoading ? (
        <Spinner
          size={22}
          color={schema === 'primary-blue' ? 'coal' : undefined}
        />
      ) : (
        <>
          {leftIcon && (
            <Icon
              name={leftIcon}
              type={leftType}
              className={cnj(classes.leftIcon, leftIconClassName)}
              color={leftColor || buttonSchema.color}
              size={leftSize}
            />
          )}
          {leftSvg}
          {!!label && (
            <Typography
              font={labelFont}
              color={labelColor || buttonSchema.color}
              className={cnj(classes.label, labelClassName)}
              size={labelSize}
              {...labelProps}
            >
              {label}
            </Typography>
          )}
          {!!children && children}
          {rightSvg}
          {rightIcon && (
            <Icon
              type={rightType}
              name={rightIcon}
              className={cnj(classes.rightIcon, rightIconClassName)}
              color={rightColor || buttonSchema.color}
              size={rightSize}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default forwardRef<any, ButtonProps>(Button);
