import React, { forwardRef } from 'react';
import cnj from '../utils/cnj';
import Link from '../Link/Link.component';
import classes from './BaseButton.module.scss';

export interface BaseButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement>) => any;
  onMouseDown?: (event: React.MouseEvent<HTMLElement>) => any;
  onMouseUp?: (event: React.MouseEvent<HTMLElement>) => any;
  onTouchEnd?: (event: TouchEvent) => void;
  onTouchMove?: (event: TouchEvent) => void;
  onTouchStart?: (event: TouchEvent) => void;
  to?: string;
  href?: string;
  className?: string;
  disabled?: boolean;
  children?: JSX.Element | React.ReactNode;
  target?: string;
  tooltip?: string;
  tPlacement?: string;
}

const BaseButton = (
  {
    onClick,
    onTouchMove,
    onMouseDown,
    onMouseUp,
    onTouchEnd,
    onTouchStart,
    to,
    href,
    className,
    disabled,
    children,
    target,
    tooltip,
    tPlacement,
  }: BaseButtonProps,
  ref: any
) => {
  const wrapperProps = !disabled
    ? href
      ? { href, target, onClick }
      : to
        ? { to }
        : { onClick }
    : {};
  const Wrapper = href ? 'a' : to && !disabled ? Link : 'span';

  return (
    <Wrapper
      data-tip={!disabled ? tooltip : undefined}
      data-place={tPlacement}
      // @ts-ignore
      ref={ref}
      {...wrapperProps}
      className={cnj(
        classes.baseButtonRoot,
        disabled && classes.disabled,
        className
      )}
      disabled={disabled}
      // @ts-ignore
      onTouchMove={onTouchMove}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      // @ts-ignore
      onTouchEnd={onTouchEnd}
      // @ts-ignore
      onTouchStart={onTouchStart}
    >
      {children}
    </Wrapper>
  );
};

export default forwardRef<any, BaseButtonProps>(BaseButton);
